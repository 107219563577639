import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";

@Component({
  selector: 'app-environmental-commodities-markets',
  standalone: true,
  templateUrl: './environmental-commodities-markets.component.html',
  styleUrl: './environmental-commodities-markets.component.scss',
  imports: [HeaderComponent, FooterComponent]

})
export class EnvironmentalCommoditiesMarketsComponent {
  email: string = 'ddevooght@sprucepower.com';

}
