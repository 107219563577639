<app-header></app-header>

<div class="environmental-section">
    <div class="container">
      <div class="row align-items-center pb-5">
        <div class="col-sm-12 pb-4 col-lg-6 text-start">
          <div class="environmental-heading">
            <h1 class="environmental-inner-heading">
              We Specialize in<br />
              Environmental Commodities
            </h1>
          </div>
          <div>
            <p class="mt-4">
              Feel confident your portfolio of solar assets is in the right
              hands. With 80,000 systems in our care, we treat your systems
              like the 50,000 we own. At Spruce, we monitor, maintain, and
              service your assets to optimize their performance, accessing our
              broad network of partners for on-site work.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6">
          <div class="environment-image">
            <img src="/assets/Environmental_Commodities_Icon.png" alt="environmental-commodities-image"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="select-option mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-5">
          <div class="marketplace-text">
            <h2 class="environmental-inner-heading">Marketplace Offerings:</h2>
          </div>
        </div>
        <div class="col-md-12 col-lg-5">
          <h2 class="mt-0 mt-md-5 mt-lg-5 marketplace-list-heading">
            Renewable Energy Credits
          </h2>
        </div>
        <div class="col-md-12 col-lg-7">
          <p>
            As an owner and operator of one of North America’s largest rooftop
            solar portfolios, Spruce’s ECM team has years of experience
            reporting, minting, and transacting RECs in PJM GATs, NEPOOL,
            NARs, and WREGIS.&nbsp; If you or your organization is seeking
            assistance in identifying, registering, minting, or sourcing of
            RECs in these registries, Spruce can assist.
          </p>
          <p>
            In addition, we can serve as a transaction partner/market maker
            for purchasing or marketing any RECs your organization owns.
          </p>
        </div>
        <div class="col-12 mt-4 mb-4">
          <div class="marketplace-line"></div>
        </div>
        <div class="col-md-12 col-lg-5">
          <h2 class="marketplace-list-heading">Carbon Credits</h2>
        </div>
        <div class="col-md-12 col-lg-7">
          <p>
            As the efforts to address climate change have arrived at the
            forefront of ESG minded investors, so to have GHG credits. Carbon
            offsets have gained strong consideration as one tool to identify,
            abate and/or remove, and track the lifecycle of carbon emissions
            globally. The science of carbon reductions has standardized the
            process of reducing carbon emissions, tracking those reductions,
            and allowing interested parties to invest and retire GHG emissions
            in achieving their internal climate goals.
          </p>
        </div>
        <div class="col-12 mt-4 mb-4">
          <div class="marketplace-line"></div>
        </div>
        <div class="col-md-12 col-lg-5">
          <h2 class="marketplace-list-heading">Marketplace Transactions</h2>
        </div>
        <div class="col-md-12 col-lg-7">
          <p>
            Spruce Power has existing relationships with numerous producers of
            high quality GHG offsets, sourced from the US and globally. We can
            identify unique certified credits related to our clients’
            investment focus, and secure reductions that fit within a bespoke
            ESG solution.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="ecm-section">
    <div class="container inner-ecm">
      <div class="row">
        <div class="col-12 col-lg-2 text-center">
          <div class="ecm-image">
            <img src="/assets/Spruce-Logo_Icon_Black.png" alt="spruce-pro-image"/>
          </div>
        </div>
        <div class="col-12 col-lg-10">
          <p>
            “Spruce’s ECM group is one of the first to register California
            residential solar sites for full SREC sales. With over 120
            megawatts registered, and more added every quarter, Spruce has
            both the skills and the scale to be one of the nation’s premier
            producers of California SRECs.”
          </p>
          <p class="david-deVooght">
            David DeVooght (Head of Environmental Commodities Markets)
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="select-option mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-5">
          <div class="marketplace-text">
            <h5 class="mb-0 ms-2">Expertise</h5>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <h2 class="expertisw-heading">Enivronmental Marketplace</h2>
          <p class="mt-2">
            Spruce Pro serves as a market maker for purchasing RECs to meet
            your goals as well as marketing any RECs your organization owns.
          </p>
        </div>
        <div class="col-md-12 col-lg-4">
          <h2 class="expertisw-heading">Buyer-Side Transacting</h2>
          <p class="mt-2">
            Spruce Pro’s market experts can help you acquire carbon offsets
            (GHG credits), RECS, and other environmental commodities to align
            with your company’s carbon goals.
          </p>
        </div>
        <div class="col-md-12 col-lg-4">
          <h2 class="expertisw-heading">Seller-Side Transacting</h2>
          <p class="mt-2">
            Spruce Pro’s robust mechanisms for registering, minting, and
            transacting in RECs makes us a right-fit partner for helping your
            organization find buyers for your environmental commodities
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="ecm-Team mb-5 mt-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="environmental-inner-heading">Reach out to Spruce’s ECM Team:</h2>
        </div>
        <div class="col-12">
          <div class="row mt-4">
            <div class="col-12 col-lg-4">
              <div>
                <img class="w-100" src="/assets/David_D_Photo.jpg" alt="david-image"/>
              </div>
            </div>
            <div class="col-12 col-lg-8 mt-3 mt-lg-0">
              <div>
                <h4>David DeVooght</h4>
                <p class="detail-david">Head of Environmental Commodities Markets</p>
                <div>
                  <p>
                    David is an experienced analyst in the energy industry.
                    With past work spanning Renewable Energy, Environmental
                    Commodities and Oil and Gas, David brings an
                    industry-leading perspective to ECMs to increase revenue,
                    lower costs and meet corporate carbon goals. David holds a
                    Master of Environmental Management from Yale University.
                  </p>
                  <p>
                    <a
                      style="
                        color: #1283c6;
                        background-color: rgba(0, 0, 0, 0);
                      "
                      href="mailto:{{email}}"
                      >{{email}}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


    <app-footer></app-footer>
